import { PropsWithChildren, useState } from 'react';

import { ReactComponent as UserProfile } from '@irc/shared-assets/illustrations/user-profile-32px.svg';
import { TYPOGRAPHY } from '@irc/shared-theme';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { LocationPin } from '../../assets/LocationPin';

import useStyles from './styles';

export interface UserMenuProps {
  title: string;
  subtitle?: string;
  location?: string;
  handleSignOut?: () => void;
}

export const UserMenu = ({
  title,
  location,
  subtitle,
  handleSignOut
}: PropsWithChildren<UserMenuProps>) => {
  const styles = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOutClick = () => {
    handleSignOut?.();
    handleClose();
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Box
        className='Header-userMenu'
        sx={{
          width: {
            xs: TYPOGRAPHY.headerM.fontSize,
            sm: TYPOGRAPHY.headerM.fontSize,
            md: TYPOGRAPHY.headerL.fontSize,
            lg: TYPOGRAPHY.headerXL.fontSize
          }
        }}
      >
        <IconButton
          aria-label='userAccount'
          aria-controls='long-menu'
          aria-haspopup='true'
          onClick={handleClick}
          className={styles.mainIcon}
        >
          <UserProfile />
        </IconButton>
      </Box>
      <Menu
        classes={{ paper: styles.paper }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem key='title' className={styles.menuItem}>
          <ListItemIcon className={styles.icon}>
            <UserProfile />
          </ListItemIcon>
          <ListItemText
            primary={title}
            primaryTypographyProps={{ className: styles.textEllipsisPrimary }}
            secondary={subtitle}
            secondaryTypographyProps={{ className: styles.textEllipsis }}
          />
        </MenuItem>
        {location && (
          <MenuItem key='location' className={styles.menuItem}>
            <ListItemIcon className={styles.icon}>
              <LocationPin width='32' height='32' />
            </ListItemIcon>
            <ListItemText
              primary={location}
              primaryTypographyProps={{ className: styles.textEllipsis }}
            />
          </MenuItem>
        )}
        {handleSignOut && (
          <div key='SignOutOption'>
            <Divider className={styles.divider} />
            <MenuItem
              key='SignOut'
              onClick={handleSignOutClick}
              className={styles.menuItemHover}
            >
              <ListItemText
                primary='Sign Out'
                primaryTypographyProps={{ className: styles.signOut }}
              />
            </MenuItem>
          </div>
        )}
      </Menu>
    </>
  );
};
