import { useCallback, useRef, useState } from 'react';

export enum InputSource {
  Manual = 'manual',
  Scanned = 'scanned',
  Pasted = 'pasted'
}

// cannot use types from SearchFieldResult here because of circular dependency, so redefining here
export enum SearchFieldResultType {
  Vin,
  StockNumber,
  CaTag
}

export type SearchFieldResult = {
  type: SearchFieldResultType;
  value: string;
};

export type ScanDetectionChangeEventType = {
  inputSource: InputSource | null;
} & SearchFieldResult;

export const useScanDetection = (
  onScanDetectionChange?: (event: ScanDetectionChangeEventType) => void
) => {
  const [inputSource, setInputSource] = useState<InputSource | null>(null);
  const lastKeyPressTime = useRef<number | null>(null);
  const isPasteEvent = useRef(false);

  const handleChange = useCallback(
    (result: SearchFieldResult) => {
      const currentTime = new Date().getTime();
      let detectedSource: InputSource;

      if (isPasteEvent.current) {
        detectedSource = InputSource.Pasted;
        isPasteEvent.current = false;
      } else {
        const timeDiffSeconds =
          (currentTime - (lastKeyPressTime.current ?? 0)) / 1000;
        if (timeDiffSeconds < 1) {
          detectedSource = InputSource.Scanned;
        } else {
          detectedSource = InputSource.Manual;
        }
      }

      lastKeyPressTime.current = null;
      setInputSource(detectedSource);

      if (onScanDetectionChange) {
        onScanDetectionChange({
          ...result,
          inputSource: detectedSource
        });
      }
    },
    [onScanDetectionChange]
  );

  const handlePaste = useCallback(() => {
    isPasteEvent.current = true;
  }, []);

  const onInput = useCallback(() => {
    if (!lastKeyPressTime.current) {
      lastKeyPressTime.current = Date.now();
    }
  }, []);

  return {
    onInput,
    handleChange,
    handlePaste,
    inputSource,
    isInputScanned: inputSource === InputSource.Scanned
  };
};
