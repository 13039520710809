/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * vTram API (TEST)
 * Provides vehicle lifecycle management within Carvana
 * OpenAPI spec version: v1
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';

import { client } from '../../client';
import type {
  ErrorResponse,
  GetVehicleDetailParams,
  VehicleDetail
} from '../../models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Get VehicleDetail using VIN, StockNumber, or CA Tag (Auth Policies: Scope:vtram_read_write)
 */
export const getVehicleDetail = (
  lookupId: string,
  params?: GetVehicleDetailParams,
  options?: SecondParameter<typeof client>,
  signal?: AbortSignal
) => {
  return client<VehicleDetail[]>(
    { url: `/api/v1/vehicledetail/${lookupId}`, method: 'GET', params, signal },
    options
  );
};

export const getGetVehicleDetailQueryKey = (
  lookupId: string,
  params?: GetVehicleDetailParams
) => {
  return [
    `/api/v1/vehicledetail/${lookupId}`,
    ...(params ? [params] : [])
  ] as const;
};

export const getGetVehicleDetailQueryOptions = <
  TData = Awaited<ReturnType<typeof getVehicleDetail>>,
  TError = ErrorResponse | void
>(
  lookupId: string,
  params?: GetVehicleDetailParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getVehicleDetail>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof client>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetVehicleDetailQueryKey(lookupId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getVehicleDetail>>
  > = ({ signal }) =>
    getVehicleDetail(lookupId, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!lookupId,
    ...queryOptions
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getVehicleDetail>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetVehicleDetailQueryResult = NonNullable<
  Awaited<ReturnType<typeof getVehicleDetail>>
>;
export type GetVehicleDetailQueryError = ErrorResponse | void;

/**
 * @summary Get VehicleDetail using VIN, StockNumber, or CA Tag (Auth Policies: Scope:vtram_read_write)
 */

export function useGetVehicleDetail<
  TData = Awaited<ReturnType<typeof getVehicleDetail>>,
  TError = ErrorResponse | void
>(
  lookupId: string,
  params?: GetVehicleDetailParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getVehicleDetail>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof client>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetVehicleDetailQueryOptions(
    lookupId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Update or Create VehicleDetail.
NOTE: This operation will overwrite all properties, if vehicle already exists. (Auth Policies: Scope:vtram_read_write)
 */
export const upsertVehicleDetail = (
  vin: string,
  vehicleDetail: VehicleDetail,
  options?: SecondParameter<typeof client>
) => {
  return client<VehicleDetail>(
    {
      url: `/api/v1/vehicledetail/${vin}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: vehicleDetail
    },
    options
  );
};

export const getUpsertVehicleDetailMutationOptions = <
  TError = ErrorResponse | void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof upsertVehicleDetail>>,
    TError,
    { vin: string; data: VehicleDetail },
    TContext
  >;
  request?: SecondParameter<typeof client>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof upsertVehicleDetail>>,
  TError,
  { vin: string; data: VehicleDetail },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof upsertVehicleDetail>>,
    { vin: string; data: VehicleDetail }
  > = (props) => {
    const { vin, data } = props ?? {};

    return upsertVehicleDetail(vin, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpsertVehicleDetailMutationResult = NonNullable<
  Awaited<ReturnType<typeof upsertVehicleDetail>>
>;
export type UpsertVehicleDetailMutationBody = VehicleDetail;
export type UpsertVehicleDetailMutationError = ErrorResponse | void;

/**
 * @summary Update or Create VehicleDetail.
NOTE: This operation will overwrite all properties, if vehicle already exists. (Auth Policies: Scope:vtram_read_write)
 */
export const useUpsertVehicleDetail = <
  TError = ErrorResponse | void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof upsertVehicleDetail>>,
    TError,
    { vin: string; data: VehicleDetail },
    TContext
  >;
  request?: SecondParameter<typeof client>;
}): UseMutationResult<
  Awaited<ReturnType<typeof upsertVehicleDetail>>,
  TError,
  { vin: string; data: VehicleDetail },
  TContext
> => {
  const mutationOptions = getUpsertVehicleDetailMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Create VehicleDetail. (Auth Policies: Scope:vtram_read_write)
 */
export const insertVehicleDetail = (
  vin: string,
  vehicleDetail: VehicleDetail,
  options?: SecondParameter<typeof client>,
  signal?: AbortSignal
) => {
  return client<VehicleDetail>(
    {
      url: `/api/v1/vehicledetail/${vin}`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: vehicleDetail,
      signal
    },
    options
  );
};

export const getInsertVehicleDetailMutationOptions = <
  TError = ErrorResponse | void | VehicleDetail,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof insertVehicleDetail>>,
    TError,
    { vin: string; data: VehicleDetail },
    TContext
  >;
  request?: SecondParameter<typeof client>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof insertVehicleDetail>>,
  TError,
  { vin: string; data: VehicleDetail },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof insertVehicleDetail>>,
    { vin: string; data: VehicleDetail }
  > = (props) => {
    const { vin, data } = props ?? {};

    return insertVehicleDetail(vin, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type InsertVehicleDetailMutationResult = NonNullable<
  Awaited<ReturnType<typeof insertVehicleDetail>>
>;
export type InsertVehicleDetailMutationBody = VehicleDetail;
export type InsertVehicleDetailMutationError =
  | ErrorResponse
  | void
  | VehicleDetail;

/**
 * @summary Create VehicleDetail. (Auth Policies: Scope:vtram_read_write)
 */
export const useInsertVehicleDetail = <
  TError = ErrorResponse | void | VehicleDetail,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof insertVehicleDetail>>,
    TError,
    { vin: string; data: VehicleDetail },
    TContext
  >;
  request?: SecondParameter<typeof client>;
}): UseMutationResult<
  Awaited<ReturnType<typeof insertVehicleDetail>>,
  TError,
  { vin: string; data: VehicleDetail },
  TContext
> => {
  const mutationOptions = getInsertVehicleDetailMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Any property which is `default` will be ignored
 * @summary Patch VehicleDetail. (Auth Policies: Scope:vtram_read_write)
 */
export const patchVehicleDetail = (
  vin: string,
  vehicleDetail: VehicleDetail,
  options?: SecondParameter<typeof client>
) => {
  return client<VehicleDetail>(
    {
      url: `/api/v1/vehicledetail/${vin}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: vehicleDetail
    },
    options
  );
};

export const getPatchVehicleDetailMutationOptions = <
  TError = ErrorResponse | void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchVehicleDetail>>,
    TError,
    { vin: string; data: VehicleDetail },
    TContext
  >;
  request?: SecondParameter<typeof client>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchVehicleDetail>>,
  TError,
  { vin: string; data: VehicleDetail },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchVehicleDetail>>,
    { vin: string; data: VehicleDetail }
  > = (props) => {
    const { vin, data } = props ?? {};

    return patchVehicleDetail(vin, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchVehicleDetailMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchVehicleDetail>>
>;
export type PatchVehicleDetailMutationBody = VehicleDetail;
export type PatchVehicleDetailMutationError = ErrorResponse | void;

/**
 * @summary Patch VehicleDetail. (Auth Policies: Scope:vtram_read_write)
 */
export const usePatchVehicleDetail = <
  TError = ErrorResponse | void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchVehicleDetail>>,
    TError,
    { vin: string; data: VehicleDetail },
    TContext
  >;
  request?: SecondParameter<typeof client>;
}): UseMutationResult<
  Awaited<ReturnType<typeof patchVehicleDetail>>,
  TError,
  { vin: string; data: VehicleDetail },
  TContext
> => {
  const mutationOptions = getPatchVehicleDetailMutationOptions(options);

  return useMutation(mutationOptions);
};
