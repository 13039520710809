export enum SearchFieldResultType {
  Vin,
  StockNumber,
  CaTag
}

export interface SearchFieldResult {
  type: SearchFieldResultType;
  value: string;
}
