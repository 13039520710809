import { PropsWithChildren } from 'react';
import $C from 'classnames';

import { styled } from '@mui/material/styles';

import { useStyles } from './styles';

type Props = {
  className?: string;
};

export const Page = ({ children, className }: PropsWithChildren<Props>) => {
  const styles = useStyles();
  // TODO: page tracking?
  return <Root className={$C(styles['@global'], className)}>{children}</Root>;
};

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden; /* lets scroll inside main. */
  background: white;
`;
