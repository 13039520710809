import { SearchFieldResult } from './types';

export interface SearchFieldState {
  invalid?: boolean;
  result?: SearchFieldResult;
}

export type SearchFieldAction =
  | { type: 'set'; payload: Partial<SearchFieldState> }
  | { type: 'reset' };

export const INITIAL_STATE = Object.freeze({
  invalid: false
});

export const reducer:
  | React.Reducer<SearchFieldState, SearchFieldAction>
  | React.ReducerWithoutAction<SearchFieldState> = (state, action) => {
  switch (action.type) {
    case 'set':
      return { ...state, ...action.payload };
    case 'reset':
      return INITIAL_STATE;
  }
};
