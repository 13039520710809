import { SearchFieldState } from './state';
import { SearchFieldResultType } from './types';

const VIN_LENGTH = 17;
const STOCK_NUMBER_LENGTH = 10;
const CA_TAG_LENGTH = 9;

export const VIN_REGEX = new RegExp(
  /^([A-HJ-NPR-Z\d]{3})([A-HJ-NPR-Z\d]{5})([\dX])(([A-HJ-NPR-Z\d])([A-HJ-NPR-Z\d])([A-HJ-NPR-Z\d]{6}))$/,
  'i'
);
export const STOCK_REGEX = new RegExp(/^[2,3][0-9]{9}$/);
export const CA_TAG_REGEX = new RegExp(/^CA.+$/i);

export function sanitizeInput(value: string): string {
  let nextValue = value;

  if (nextValue.length >= 18 && nextValue.toLowerCase().startsWith('i')) {
    nextValue = nextValue.slice(1);
  }

  return nextValue.trim();
}

export function isValidLookupLength(value: string): boolean {
  return (
    value.length === STOCK_NUMBER_LENGTH ||
    value.length === VIN_LENGTH ||
    (value.length === 18 && value.toLowerCase().startsWith('i')) ||
    value.length === CA_TAG_LENGTH
  );
}

export function isStockNumber(value: string): boolean {
  return value.length === STOCK_NUMBER_LENGTH && STOCK_REGEX.test(value);
}

export function isVin(value: string): boolean {
  return value.length === VIN_LENGTH && VIN_REGEX.test(value);
}

export function isCaTag(value: string): boolean {
  return CA_TAG_REGEX.test(value);
}

export function isValidLookup(value: string): boolean {
  return isStockNumber(value) || isVin(value) || isCaTag(value);
}

export const processSearchTerm = (
  searchTerm: string
): SearchFieldState | undefined => {
  const nextValue = sanitizeInput(searchTerm);
  if (isValidLookupLength(nextValue)) {
    if (isStockNumber(nextValue)) {
      return {
        result: { value: nextValue, type: SearchFieldResultType.StockNumber }
      };
    }

    if (isVin(nextValue)) {
      return { result: { value: nextValue, type: SearchFieldResultType.Vin } };
    }

    if (isCaTag(nextValue)) {
      return {
        result: { value: nextValue, type: SearchFieldResultType.CaTag }
      };
    }
  }

  return;
};
