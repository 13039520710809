/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Carvana Vehicle Position Service TEST
 * OpenAPI spec version: v1
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';

import type { ErrorType } from '../../client';
import { client } from '../../client';
import type {
  CreatePositionRequest,
  GetLastPositionsParams,
  GetLastPositionsWithinDistanceParams,
  GetPositionHistoryParams,
  LastPositionQueryResult,
  LastPositionsQueryResult,
  Position,
  PositionHistoryQueryResult
} from '../../models';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Ingests a new position for a vehicle. (Auth policies: WriteAccessRequired)
 */
export const ingestPosition = (
  createPositionRequest: CreatePositionRequest,
  options?: SecondParameter<typeof client>,
  signal?: AbortSignal
) => {
  return client<Position>(
    {
      url: `/api/v1/position`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createPositionRequest,
      signal
    },
    options
  );
};

export const getIngestPositionMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof ingestPosition>>,
    TError,
    { data: CreatePositionRequest },
    TContext
  >;
  request?: SecondParameter<typeof client>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof ingestPosition>>,
  TError,
  { data: CreatePositionRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof ingestPosition>>,
    { data: CreatePositionRequest }
  > = (props) => {
    const { data } = props ?? {};

    return ingestPosition(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type IngestPositionMutationResult = NonNullable<
  Awaited<ReturnType<typeof ingestPosition>>
>;
export type IngestPositionMutationBody = CreatePositionRequest;
export type IngestPositionMutationError = ErrorType<void>;

/**
 * @summary Ingests a new position for a vehicle. (Auth policies: WriteAccessRequired)
 */
export const useIngestPosition = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof ingestPosition>>,
    TError,
    { data: CreatePositionRequest },
    TContext
  >;
  request?: SecondParameter<typeof client>;
}): UseMutationResult<
  Awaited<ReturnType<typeof ingestPosition>>,
  TError,
  { data: CreatePositionRequest },
  TContext
> => {
  const mutationOptions = getIngestPositionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Gets the last known position for a specific vehicle. (Auth policies: ReadAccessRequired)
 */
export const getLastPosition = (
  vin: string,
  options?: SecondParameter<typeof client>,
  signal?: AbortSignal
) => {
  return client<LastPositionQueryResult>(
    { url: `/api/v1/position/last/${vin}`, method: 'GET', signal },
    options
  );
};

export const getGetLastPositionQueryKey = (vin: string) => {
  return [`/api/v1/position/last/${vin}`] as const;
};

export const getGetLastPositionQueryOptions = <
  TData = Awaited<ReturnType<typeof getLastPosition>>,
  TError = ErrorType<void>
>(
  vin: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLastPosition>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof client>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLastPositionQueryKey(vin);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLastPosition>>> = ({
    signal
  }) => getLastPosition(vin, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!vin,
    ...queryOptions
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getLastPosition>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetLastPositionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLastPosition>>
>;
export type GetLastPositionQueryError = ErrorType<void>;

/**
 * @summary Gets the last known position for a specific vehicle. (Auth policies: ReadAccessRequired)
 */

export function useGetLastPosition<
  TData = Awaited<ReturnType<typeof getLastPosition>>,
  TError = ErrorType<void>
>(
  vin: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLastPosition>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof client>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetLastPositionQueryOptions(vin, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Gets the last known positions for all vehicles updated after a specific date. (Auth policies: ReadAccessRequired)
 */
export const getLastPositions = (
  params?: GetLastPositionsParams,
  options?: SecondParameter<typeof client>,
  signal?: AbortSignal
) => {
  return client<LastPositionsQueryResult>(
    { url: `/api/v1/position/updated`, method: 'GET', params, signal },
    options
  );
};

export const getGetLastPositionsQueryKey = (
  params?: GetLastPositionsParams
) => {
  return [`/api/v1/position/updated`, ...(params ? [params] : [])] as const;
};

export const getGetLastPositionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getLastPositions>>,
  TError = ErrorType<void>
>(
  params?: GetLastPositionsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLastPositions>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof client>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetLastPositionsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLastPositions>>
  > = ({ signal }) => getLastPositions(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getLastPositions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetLastPositionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLastPositions>>
>;
export type GetLastPositionsQueryError = ErrorType<void>;

/**
 * @summary Gets the last known positions for all vehicles updated after a specific date. (Auth policies: ReadAccessRequired)
 */

export function useGetLastPositions<
  TData = Awaited<ReturnType<typeof getLastPositions>>,
  TError = ErrorType<void>
>(
  params?: GetLastPositionsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLastPositions>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof client>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetLastPositionsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Gets the position history for a specific vehicle within a date range. (Auth policies: ReadAccessRequired)
 */
export const getPositionHistory = (
  vin: string,
  params?: GetPositionHistoryParams,
  options?: SecondParameter<typeof client>,
  signal?: AbortSignal
) => {
  return client<PositionHistoryQueryResult>(
    { url: `/api/v1/position/history/${vin}`, method: 'GET', params, signal },
    options
  );
};

export const getGetPositionHistoryQueryKey = (
  vin: string,
  params?: GetPositionHistoryParams
) => {
  return [
    `/api/v1/position/history/${vin}`,
    ...(params ? [params] : [])
  ] as const;
};

export const getGetPositionHistoryQueryOptions = <
  TData = Awaited<ReturnType<typeof getPositionHistory>>,
  TError = ErrorType<void>
>(
  vin: string,
  params?: GetPositionHistoryParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPositionHistory>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof client>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPositionHistoryQueryKey(vin, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPositionHistory>>
  > = ({ signal }) => getPositionHistory(vin, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!vin,
    ...queryOptions
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPositionHistory>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPositionHistoryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPositionHistory>>
>;
export type GetPositionHistoryQueryError = ErrorType<void>;

/**
 * @summary Gets the position history for a specific vehicle within a date range. (Auth policies: ReadAccessRequired)
 */

export function useGetPositionHistory<
  TData = Awaited<ReturnType<typeof getPositionHistory>>,
  TError = ErrorType<void>
>(
  vin: string,
  params?: GetPositionHistoryParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPositionHistory>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof client>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetPositionHistoryQueryOptions(vin, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Gets the last known positions for all vehicles within a specified distance and updated after a specific date. (Auth policies: ReadAccessRequired)
 */
export const getLastPositionsWithinDistance = (
  params: GetLastPositionsWithinDistanceParams,
  options?: SecondParameter<typeof client>,
  signal?: AbortSignal
) => {
  return client<LastPositionQueryResult>(
    { url: `/api/v1/position/last/radius`, method: 'GET', params, signal },
    options
  );
};

export const getGetLastPositionsWithinDistanceQueryKey = (
  params: GetLastPositionsWithinDistanceParams
) => {
  return [`/api/v1/position/last/radius`, ...(params ? [params] : [])] as const;
};

export const getGetLastPositionsWithinDistanceQueryOptions = <
  TData = Awaited<ReturnType<typeof getLastPositionsWithinDistance>>,
  TError = ErrorType<void>
>(
  params: GetLastPositionsWithinDistanceParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLastPositionsWithinDistance>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof client>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetLastPositionsWithinDistanceQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLastPositionsWithinDistance>>
  > = ({ signal }) =>
    getLastPositionsWithinDistance(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getLastPositionsWithinDistance>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetLastPositionsWithinDistanceQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLastPositionsWithinDistance>>
>;
export type GetLastPositionsWithinDistanceQueryError = ErrorType<void>;

/**
 * @summary Gets the last known positions for all vehicles within a specified distance and updated after a specific date. (Auth policies: ReadAccessRequired)
 */

export function useGetLastPositionsWithinDistance<
  TData = Awaited<ReturnType<typeof getLastPositionsWithinDistance>>,
  TError = ErrorType<void>
>(
  params: GetLastPositionsWithinDistanceParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLastPositionsWithinDistance>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof client>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetLastPositionsWithinDistanceQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}
